import createBreakpoints from './createBreakpoints';

const theme = {
  colors: {
    black: '#000',
    primaryBlack2: '#1d191b',
    textDefault: '#000',
    primaryBlack: '#202525',
    secondaryBlack: '#212626',
    tertiaryBlack: '#222',
    transparentBlack: 'rgba(0, 0, 0, 0.1)',
    backgroundBlack: 'rgba(0, 0, 0, 0.33)',
    hoverBackgroundBlack: 'rgba(0, 0, 0, 0.9)',
    darkTransparentBlack: 'rgba(0, 0, 0, 0.4)',
    boxShadowGray: 'rgba(35, 31, 32, 0.3)',
    secondaryBoxShadowGray: 'rgba(0, 0, 0, .2)',
    borderLightGray: 'rgb(214, 214, 214)',
    borderLightGray2: '#cacaca',
    backgroundLightGray: 'rgb(241, 241, 241)',
    hoverLightGray: 'rgb(241, 241, 241)',
    coolBlack: '#1A1A1A',
    primaryRed: '#e21f26',
    ttRed: '#e02529',
    secondaryRed: '#dd2528',
    tertiaryRed: '#e2252a',
    lightRed: '#f6413b',
    darkRed: '#ba1f21',
    darkerRed: '#a51c1e',
    primaryRedTransparent: 'rgba(224, 37, 41, 0.7)',
    secondaryRedTransparent: 'rgba(221, 37, 40, 0.6)',
    darkerRedTransparent: 'rgba(165, 28, 30, 0.5)',
    ultraRed: '#eb021e',
    apple: '#be012b',
    lightPink: '#ffdede',
    white: '#fff',
    whiteSmoke: '#f7f7f7',
    primaryTransparentWhite: 'hsla(0, 0%, 100%, 0.3)',
    secondaryTransparentWhite: 'hsla(0, 0%, 100%, 0.85)',
    primaryOffWhite: '#ededed',
    secondaryOffWhite: '#f8f8f8',
    macGray: '#e5e5e5',
    offGray: '#f0f0f0',
    coolBlue: '#97cadf',
    cloudGray: '#d0d0d3',
    darkerGray: '#444444',
    iconHoverGray: '#efefef',
    darkGray: '#707070',
    slateGray: '#758696',
    gray: '#838a8a',
    silver: '#6f6f6f',
    lightGray: '#b2b8b8',
    lighterGray: '#ddd',
    secondaryGray: '#555555',
    secondaryGray2: '#e9e9e9',
    secondaryGray3: '#eeeeee',
    secondaryGray4: '#e0e0e0',
    secondaryGray5: '#f1f1f1',
    lightestGray: '#f3f3f3',
    igOffWhite: 'rgb(245, 246, 247)',
    hoverGray: '#333',
    smokeGray: '#aaaaaa',
    mutedGray: 'rgba(29, 25, 27, .65)',
    transparentGray: 'rgba(255, 255, 255, 0.6)',
    brightGreen: '#58fe09',
    coolGreen: 'hsla(150, 52%, 51%, 1)',
    cyanBlue: '#04eaff',
    lightBlue: '#7de5ea',
    lightNavyBlue: '#3898ec',
    lighterNavyBlue: '#5d93eb',
    titleBlue: '#065bdb',
    skyBlue: '#7de2ff',
    navyBlue: '#0082f3',
    websiteBlue: '#007ABF',
    upBlue: '#083374',
    seaGreen: '#52dca8',
    grayscaleGreen: '#c8cbc8',
    darkGrayscaleGreen: '#999998',
    turquoise: '#67d8cd',
    grayscaleBlue: '#ebeded',
    promoPrimaryGray: '#b2b8b8',
    promoSecondaryGray: '#bcc3c3',
    mustardYellow: '#ffc03a',
    cryptoYellow: '#dbae4b',
    twitterBlue: '#1da1f2',
  },
  breakpoints: {
    // Add breakpoint helper functions (taken from material-ui).
    ...createBreakpoints({
      values: {
        xxs: 320,
        xs: 479,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1600,
      },
      unit: 'px',
    }),
    // Values defined below are kept for backwards-compatibility.
    xxs: '320px',
    xs: '479px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1600px',
  },
  buttons: {
    default: {
      color: '#fff',
      backgroundColor: '#000',
    },
    primary: {
      color: '#fff',
      backgroundColor: '#e02529',
    },
    header: {
      color: '#fff',
      backgroundColor: 'linear-gradient(#e02529, #dd2528, #a51c1e)',
    },
    footer: {
      color: '#fff',
      backgroundColor: 'linear-gradient(#e02529, #dd2528, #a51c1e)',
    },
  },
  fonts: {
    default: '"Worksans Variablefont Wght", sans-serif',
    default_fallback: '"Open Sans", Tahoma, sans-serif',
    condensed: '"Open Sans Condensed", Impact, "Franklin Gothic", sans-serif',
    condensed_fallback: '"Open Sans Condensed", Arial-fallback, sans-serif',
    condensed_fallback1: '"Open Sans Condensed", Arial-fallback1, sans-serif',
    fontFamilySecondary: "'Tusker Grotesk 6500', sans-serif",
    faSolid900: "'Fa Solid 900', sans-serif",
  },
  fontWeights: {
    subheaderFontWeight: '700',
  },
};

export default theme;

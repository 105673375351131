export type BreakPoints = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

interface CreateBreakPointsI {
  values: {
    [key in BreakPoints]: number;
  };
  unit: 'px' | 'rem' | 'em';
  step?: number;
}

// Keep in mind that @media is inclusive by the CSS specification.
export default function createBreakpoints(breakpoints: CreateBreakPointsI) {
  const {
    // The breakpoint **start** at this value.
    // For instance with the first breakpoint xs: [xs, sm).
    values = {
      xxs: 0,
      xs: 0, // phone
      sm: 600, // tablet
      md: 900, // small laptop
      lg: 1200, // desktop
      xl: 1536, // large screen
      xxl: 1536,
    },
    unit = 'px',
    step = 5,
    ...other
  } = breakpoints;

  const keys: BreakPoints[] = Object.keys(values) as BreakPoints[]; // dangerous assertion but needed here since ts doesnt discern the type of Object map input

  function up(key: BreakPoints) {
    const value = values[key];
    return `@media (min-width:${value}${unit})`;
  }

  function down(key: BreakPoints) {
    const value = values[key];
    return `@media (max-width:${value - step / 100}${unit})`;
  }

  function between(start: BreakPoints, end: BreakPoints) {
    const endIndex = keys.indexOf(end);

    return (
      `@media (min-width:${typeof values[start] === 'number' ? values[start] : start}${unit}) and ` +
      `(max-width:${values[keys[endIndex]] - step / 100}${unit})`
    );
  }

  function only(key: BreakPoints) {
    if (keys.indexOf(key) + 1 < keys.length) {
      return between(key, keys[keys.indexOf(key) + 1]);
    }

    return up(key);
  }

  return {
    keys,
    values,
    up,
    down,
    between,
    only,
    unit,
    ...other,
  };
}
